@import "../../../components/shared/config.module.css";

.modalText {
  font-family: var(--font-family);
  color: var(--blue-black);
  font-size: 1em;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}

.spacer {
  padding-bottom: 1em;
}

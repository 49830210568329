.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}

.background {
  position: absolute;
  top: 0.3px;
  left: 0.3px;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 6px;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.2);
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--pale-grey);
  border-radius: 6px;
  
  clip-path: polygon(
    0% 0%,         /* Top-left corner */
    80% 0%,        /* Right-top cut */
    100% 50%,      /* Top-right cut */
    100% 50%,      /* Bottom-right cut */
    80% 100%,      /* Right-bottom cut */
    0% 100%        /* Bottom-left corner */
  );
}

.content {
  display: flex;
  flex-direction: column;
  padding-right: 7em;
  padding-top: 3em;
  padding-left: 3em;
  padding-bottom: 3em;
  gap: 1em;
}

.row1 {
  display: flex;
  gap: 2em;
  width: 100%;
}

.row1 .item1 {
  width: 80px;
  min-width: 80px;
}

.row1 .item2 {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.row2 {
  width: 100%;
}

.item {
  box-sizing: border-box;
}

.text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
}

.addressSearchContainer {
  display: flex;
  height: 42px;
  width: 100%;
}

.addressSearchIcon {
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid #ced4da;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.addressSearchInput {
  display: flex;
  flex: 1;
  text-align: left;
}

.addressSearch__control {
  height: 42px;
  width: 455px;
}

.spinner {
  font-family: var(--font-family);
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.menuItem {
  display: flex;
  font-size: 13px;
}

.menuItemAddress {
  display: flex;
}

.arrow {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  border: solid gray;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  width: 3px;
  height: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.disabled {
  display: none;
}

.validationError {
  color: var(--negative);
  size: 0.8em;
  font-weight: 600;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@import "../../../components/shared/config.module.css";

.panelInstruction {
  display: flex;
  flex-direction: row;
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  background-color: var(--pale-grey);
  border-radius: 6px;
  height: 60px;
}

.panelInstructionText {
  padding-top: 1.2em;
  padding-left: 1.2em;
}

.panelDescription {
  color: var(--blue-black);
  padding: 1em;
}

.panelBody {
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.panelButton {
  padding: 1em;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 1.2em;
  color: var(--blue-black);
  background-color: #F4F4F4;
  border: none;
  text-align: left;
}

.selectedPanelButton {
  background-color: #D5EEF7;
}

.panelButton:hover {
  background-color: #D5EEF7;
}
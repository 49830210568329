.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.875rem;
}

.label-container {
  display: flex;
}

.info-text {
  margin-left: 0.25rem;
  margin-top: 0.1rem;
}

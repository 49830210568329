@import "../../components/shared/config.module.css";

.paginationContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.spinner {
    font-family: var(--font-family);
    position: relative;
    width: 26px;
    height: 26px;
    margin-right: 20px;
}

.loading {
    font-family: var(--font-family);
    display: flex;
    justify-content: center;
    padding: 20px;
}

.accordion {
    text-align: left;
}

.title {
    composes: h1 from "../../components/shared/typography.module.css"
}

.itemContentRow {
    justify-content: space-between;
}

.itemContentColumn {
    padding-left: 0;
}

.caseCount {
    font-family: var(--font-family);
    float: right;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
}

.newUpdatesCases {
    color: var(--pink);
}

.activeCases {
    color: var(--light-blue);
}

.closedCases {
    color: var(--purple);
}

.cardBody {
    font-family: var(--font-family);
    padding: 0 0 0 1.25rem;
}

.cardBodyFirst {
    font-family: var(--font-family);
    padding: 1.25rem 0 0 1.25rem;
}

.card {
    border: none;
}

.headerText {
    font-family: var(--font-family);
    line-height: 3rem;
}

.button {
    margin: 0 5px;
}   

.bottompad2x {
    composes: bottompad2x from "../../components/shared/layout.module.css"
}

.bottompad1x {
    composes: bottompad1x from "../../components/shared/layout.module.css"
}

.spinnerContainer {
    display: flex;
    justify-content: center;
}
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px; 
}

.item {
  background-color: #f0f0f0;
  text-align: center;
  width: 500px;
}

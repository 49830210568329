.subtotalsContainer {
    padding: 1rem 2.5rem 0;
    composes: displayColumnWidth from "../../utils/common.module.css"
}

.subtotalsTable td {
    padding: 0.5rem 0;
    font-weight: 700;
}

.totalsTableValueColumn {
    text-align: right;
}

@media(min-width: 768px) {
    .subtotalsContainer {
        width: 42vw;
        composes: displayColumnWidth from "../../utils/common.module.css"
    }
}

.subtotalContainer {
    display: flex;
}

.totalContainer {
    composes: displayColumnWidth from "../../utils/common.module.css";
    display: flex;
    padding: 0.625rem 2.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    border: 2px solid var(--light-blue);
    border-radius: 5px;
    line-height: 1.5rem;
    justify-content: space-between;
}

.totalHint {
    font-size: 0.875rem;
    font-weight: 300;
}


.breadcrumbContainer>ol {
  background-color: white;
}

.breadcrumbItem+.breadcrumbItem::before {
  content:var(--bs-breadcrumb-divider,">") !important ;
}

.breadcrumbItemActive {
  background-color: white !important ;
  color: #6c757d;
}
@import "../../shared/config.module.css";

.toggleButton {
  margin-right: 10px !important;
  background-color: var(--pale-blue);
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 1em;
  color: var(--blue-black);
  border: none;
  padding: 0.6em 0 0.6em 0;
}

.error {
  font-family: var(--font-family);
  color: var(--negative);
  font-weight: 600;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  white-space: pre-wrap;
}

.flexcontainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  width: 30em;
  text-align: center;
  outline-style: none;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flexitem {
  cursor: pointer;
  color: var(--blue-black);
  font-family: var(--font-family);
  font-weight: 300;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}


.header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-transform: uppercase;
    background-color: transparent;
    text-align: left;
    color: var(--blue-black);
    font-size: 1.4em;
    font-weight: 300;
    font-family: var(--font-family);
    margin-bottom: 1em;
    border: 0;
}

.panelInstruction {
  display: flex;
  flex-direction: row;
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  background-color: #D5EEF7;
  border-radius: 6px;
  height: 60px;
}

.panelInstructionText {
  padding-top: 1.2em;
  padding-left: 1.2em;
}

.tableDescription {
  padding-top: 1.2em;
  padding-bottom: 1.2em;
}

.tableContainer {
  background-color: var(--pale-grey);
  padding : 1.2em;
  width: 100%;
}
.text {
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 16px;
}

.actionButton {
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  color: var(--blue-black);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  padding: 0.5rem 0.75rem;
}

.actionButton:hover,
.actionButton:focus {
  background-color: var(--white);
  border: 1px solid var(--blue-black);
  color: var(--blue-black);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  padding: 0.5rem 0.75rem;
}
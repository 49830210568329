.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.dropdownContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dropdown {
    width: 100%
}

.dropdownToggle {
    background: transparent;
    border: 1px solid var(--grey);
    border-radius: 5px;
    color: var(--blue-black);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdownDrawer {
    width: 100%
}

.dropdownToggle:focus {
    background: transparent !important;
    color: var(--blue-black) !important;
}

.dropdownToggle:hover {
    background: transparent !important;
    color: var(--blue-black) !important;
}

.dropdownToggle:active {
    background: transparent !important;
    color: var(--blue-black) !important;
}

.otherTitleContainer {
    margin-top: 1rem;
}
.button {
  background: transparent;
  border: 0;
  position: relative;
  padding: 0;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

.iconAsc {
  transform: rotate(180deg) translateY(5px);
}

.icon svg {
  display: block;
}

@import "../../../components/shared/config.module.css";

body {
  padding-bottom: 100px !important;
}

.label {
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  padding-bottom: 0.75em;
  padding-top: 2em;
}

.labelFirst {
  padding-top: 0;
}
.leftpad1x {
  padding-left: 1em;
}

.leftpad4x {
  padding-left: 3em;
}

.leftpad6x {
  padding-left: 6em;
}

.leftpad7x {
  padding-left: 7em;
}

.leftpad125x {
  padding-left: 1.25em;
}

.rightpad1x {
  padding-right: 1em;
}

.rightpad2x {
  padding-right: 2em;
}

.rightpad6x {
  margin-right: 6em;
}

.toppad2x {
  padding-top: 2em;
}

.bottompad1x {
  padding-bottom: 1em !important;
}

.toppad1x {
  padding-top: 1em !important;
}

.toppad03x {
  padding-top: 0.3em;
}

.bottompad2x {
  padding-bottom: 2em !important;
}
.bottommargin2x {
  margin-bottom: 2em !important;
}

.bottompad5x {
  padding-bottom: 5em !important;
}

.leftalign {
  text-align: left;
}

.input {
  font-family: var(--font-family);
  font-size: 1em !important;
  background-color: white !important;
  color: var(--blue-black);
  font-weight: 300;
}

.spacer {
  padding-bottom: 1em;
}

.toast {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    z-index: 1051;
    color: var(--white);
    border-radius: 0;
}

.header {
    padding: 0.45rem 1.75rem 0.5rem;
    justify-content: space-between;
    background-color: transparent;
    border: 0;
    color: var(--white);
    font-weight: 500;
}

.positive {
    background-color: var(--positive);
}

.negative {
    background-color: var(--negative);
}

@import "../../components/shared/config.module.css";

.error {
  color: var(--negative);
  font-weight: 500;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

p {
  font-family: var(--font-family);
}

.savepreferences {
  font-family: var(--font-family);
  font-weight: 300;
  background-color: var(--blue-black);
  border: 1px solid var(--blue-black);
  color: var(--white);
  border-radius: 1.5em;
  font-size: 0.9em;
  line-height: 1.4em;
  margin-left: 1em;
  padding: 0;
  height: 3em;
  width: 16em;
}

.savepreferences:hover, .linkuinsure:hover {
  background-color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
}

.linkuinsure {
 height: 2.2rem;
 font-size: 0.9rem;
}

.cardimage {
  width: 5em;
  height: 5em;
}

.input {
  font-family: var(--font-family);
  font-size: 1.1em !important;
  background-color: white !important;
  color: var(--dark-blue);
  font-weight: 300;
  margin-top: 1em;
  margin-bottom: 1em;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-size: 1.4em;
  font-weight: 500;
  font-family: var(--font-family);
  margin-bottom: 1em;
}

.uinsuretitle {
  font-family: var(--font-family);
  font-size: 1.4em;
  color: var(--purple);
  font-weight: 100;
}

.uinsurelogo {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.uinsuretext {
  font-family: Montserrat;
  font-size: 1rem;
  color: var(--blue-black);
  font-weight: 100;
}

.subtitle {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-size: 1em;
  font-weight: 300;
  font-family: var(--font-family);
  margin-bottom: 2.5em;
}

.user {
  font-weight: 900;
  padding-left: 0.4em;
  text-transform: uppercase;
}

.inlineflex {
  display: inline-flex;
}

.leftpad6x {
  padding-left: 6em;
}

.leftpad2x {
  padding-left: 2em;
}

.rightpad6x {
  margin-right: 6em;
}

.bottompad2x {
  padding-bottom: 2em !important;
}

.bottompad1x {
  padding-bottom: 1em !important;
}

.bottompad4x {
  padding-bottom: 4em !important;
}

.toppad2x {
  padding-top: 2em !important;
}

.toppad15x {
  padding-top: 1.5em;
}

.leftalign {
  text-align: left;
}

.rightalign {
  text-align: right;
}

.yourlawfirms {
  padding-top: 2.5em;
  margin-bottom: 2.5em;
  background-color: var(--pale-grey);
  min-height: 3em;
}

.lawfirmstitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: var(--font-family);
  font-size: 1.4em;
  color: var(--pink);
  font-weight: 100;
  line-height: 1.2em;
}

.lawfirmstext {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-back);
  font-weight: 300;
  line-height: 1.2em;
  margin-top: 2.5em;
}

.flexcontainer {
  display: inline-block;
  text-align: center;
  outline-style: none;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flexitem {
  color: var(--dark-blue);
  font-family: var(--font-family);
  font-weight: 300;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.spinner {
  font-family: var(--font-family);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-right: 20px;
}

.loading {
  font-family: var(--font-family);
  display: flex;
  justify-content: center;
  padding: 20px;
}

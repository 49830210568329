.formLabel { 
    display: flex;
}

.dropDownContainer { 
    display: flex;
}

.dropDownContainerChild {
    flex: 0 1 0;
}

.phoneNumberContainer { 
    flex: 1;
}

.phoneNumberControl { 
    display: inline;
}

.errorMessages {
    text-align: left;
}
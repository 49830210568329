.partnershipContainer {
  width: 100%;
  height: 100%;
}

.partnershipContainer span {
  display: flex;
}

.partnershipInnerContainer {
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.partnershipContent {
  flex-direction: row;
  align-items: flex-end
}

.flex {
  display: flex;
}

.partnershipText {
  margin-right: 10px;
}

.selectLogo {
  height: 100% !important;
}
@import "../../components/shared/config.module.css";

body {
  padding-bottom: 100px !important;
}

.viewCaseSummaryContainer {
  font-family: var(--font-family);
  text-align: left;
}

.pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-size: 1.4em;
  font-weight: 300;
  font-family: var(--font-family);
  margin-bottom: 2em;
}

.noDetails {
  font-family: var(--font-family);
  text-align: left;
  margin-bottom: 2.5em;
}

.spinner {
  font-family: var(--font-family);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-right: 20px;
}

.loading {
  font-family: var(--font-family);
  display: flex;
  justify-content: center;
  padding: 20px;
}

.summaryCard {
  background-color: var(--pale-grey);
  width: 100%;
  padding: 2.5em 2.5em 1em 2.5em;
  border-radius: 6px;
  margin-bottom: 2.5em;
}

.summaryInfo {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.label {
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-family: var(--font-family);
  color: var(--blue-black);
  padding-bottom: 1em;
  width: 300px;
}

.response {
  display: flex;
  flex-direction: row;
  font-family: var(--font-family);
  font-weight: 700;
  color: var(--blue-black);
  padding-bottom: 1em;
}

.clientNames {
  display: flex;
  flex-direction: column;
}

.accordionContainer {
  margin-bottom: 2.5em;
  border: 0;
}

.accordionHeader {
  font-family: var(--font-family);
  background-color: var(--pale-grey);
  border: 0;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 0.75em 2.5em;
}

.sectionHeader {
  background-color: var(--pale-grey);
  padding: 1em 1em 1em 2.5em;
  margin-bottom: 2.5em;
  border: 0;
}

.quoteSectionContent {
  background-color: var(--white);
  border: 0;
}

.sectionContent {
  background-color: var(--white);
  border: 0;
  padding-left: 2.4em;
  padding-top: 2em;
}

.accordion {
  text-align: left;
}

.span {
  float: right;
  font-family: var(--font-family);
}

.iconToggle {
  float: right;
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  padding-right: 0.4em;
}

.cardBody {
  padding: 0 0 0 1.25rem;
  font-family: var(--font-family);
}

.cardBodyFirst {
  padding: 1.25rem 0 0 1.25rem;
  font-family: var(--font-family);
}

.card {
  border: 0;
  margin-bottom: 2.5em;
  border-radius: 6px;
}

.headerText {
  font-family: var(--font-family);
  font-size: 1em;
  line-height: 3.2rem;
  margin-bottom: 2.5em;
  padding-left: 1em;
}

.button {
  margin-bottom: 2.5em;
  max-width: 200px;
}

.bottomPad2x {
  margin-bottom: 2.5em;
}

.filterButton {
  border: none;
  padding: 20px;
  cursor: pointer;
  width: 10rem;
  font-family: var(--font-family);
  background-color: var(--pale-blue);
  color: var(--blue-black);
  height: 2rem;
  display: inline-flex;
  align-items: center; 
  justify-content: center;
  margin-bottom: 2rem;
}

.activeButton {
  background-color: var(--blue-black);
  color: var(--white);
}

.activeButton:hover {
  color: var(--white);
}

.filterButton:nth-of-type(1) {
  border-radius: 10px 0px 0px 10px;
}

.filterButton:nth-of-type(2) {
  border-radius: 0px 0px 0px 0px;
}

.filterButton:nth-of-type(3) {
  border-radius: 0px 10px 10px 0px;
}
.row {
    border-bottom: 1px solid var(--light-blue);
    padding-bottom: 1rem;
}

.total {
    border: none;
}

.table { 
    width: 100%;
    margin-bottom: 0;
}

.th, .td {
    font-weight: 300;
    text-align: left;
    padding: 1em 15px 0;
    font-family: var(--font-family);
    font-size: 1rem;
    width: 80%;
}

.total {
    font-size: 1.5rem;
    font-weight: 500;
}

.note {
    font-size: 1rem;
    font-weight: normal;
}

.td {
    width: 20%
}

.dialog {
    display: flex;
    justify-content: center;
 }
 
 .dialog-content {
    min-width: 42.5rem;
    padding: 1.5rem;
 }
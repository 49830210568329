.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 8px;
}

.iconButton {
  height: 36px;
  width: 36px;
}

.iconButtonWrapper {
  margin-left: 5px;
}

.textField {
  margin-top: auto;
  margin-bottom: auto;
}

.errorCell {
  color: #dc3545;
}
@import "../../components/shared/config.module.css";

body {
  padding-bottom: 100px !important;
}
.intro {
 composes: h1 from "../../components/shared/typography.module.css";
}

.next {
  font-family: var(--font-family);
  font-weight: 300;
  background-color: var(--blue-black);
  border: 1px solid var(--blue-black);
  color: var(--white);
  border-radius: 1.5em;
  font-size: 1em;
  line-height: 1.4em;
  margin-left: 1em;
  margin-bottom: 4em;
  height: 3em;
  width: 10em;
}

.next:hover {
  background-color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
}

.label {
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  padding-bottom: 0.75em;
  padding-top: 2em;
}

.labelFirst {
  padding-top: 0;
}

.traitLabel {
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  padding-bottom: 0.75em;
  padding-left: 3em;
}

.traitField {
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  padding-bottom: 0.75em;
  padding-top: 0em;
  padding-left: 3em;
}

.instruction {
  display: flex;
  flex-direction: row;
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  margin-bottom: 1em;
}

.toggleButton {
  margin-right: 10px !important;
  background-color: white;
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 1.2em;
  color: var(--blue-black);
}

.instructionImg {
  display: flex;
  flex-direction: row;
  height: 2.2em;
  width: 2.2em;
  padding-right: 1em;
}

.instructionAlign {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: 0em;
}

.instructionText {
  padding-top: 0.4em;
}

.stepInstruction {
  display: flex;
  flex-direction: row;
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  background-color: var(--pale-grey);
  border-radius: 6px;
  height: 60px;
}

.stepInstructionImg {
  height: 3em;
  width: 3em;
  padding-right: 1em;
  padding-top: 0.7em;
}

.stepInstructionAlign {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: 0em;
}

.stepInstructionText {
  padding-top: 1.2em;
}

.inlineflex {
  display: inline-flex;
}

.leftpad4x {
  padding-left: 3em;
}

.leftpad6x {
  padding-left: 6em;
}

.leftpad7x {
  padding-left: 7em;
}

.leftpad125x {
  padding-left: 1.25em;
}

.rightpad6x {
  margin-right: 6em;
}

.toppad2x {
  padding-top: 2em;
}

.bottompad1x {
  padding-bottom: 1em !important;
}

.bottompad5x {
  padding-bottom: 5em !important;
}

.leftalign {
  text-align: left;
}

.rightalign {
  text-align: right;
}

.input {
  font-family: var(--font-family);
  font-size: 1em !important;
  background-color: white !important;
  color: var(--blue-black);
  font-weight: 300;
}

.manualAddressAdd {
  background-color: var(--pale-grey);
  padding: 2.5em;
  width: 50%;
  font-family: var(--font-family);
  font-size: 1em;
  text-align: left;
  border-radius: 6px;
}

.caseTypeTitle {
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  padding-bottom: 0.75em;
  padding-top: 2em;
  text-align: left;
  position: relative;
}

.caseTypeTitle::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: -15px;
  height: 1px;
  width: calc(100% + 30px);
  background-color: var(--light-blue);
}

.infoText {
  font-family: var(--font-family);
  font-size: 0.8em;
  color: var(--blue-black);
  font-weight: 300;
  padding-bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  padding-left: 3.8em;
  width: 400px;
}

.traitsCardVisible {
  background-color: var(--pale-grey);
  padding: 2.5em;
  border-radius: 6px 6px 6px 6px;
  margin-bottom: 3em;
}

.traitsCardHidden {
  background-color: var(--white);
  margin-bottom: 0em;
}

.verticalFlow {
  height: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.verticalFlowPurchase {
  min-height: 300px;
}

.error {
  color: var(--negative);
  size: 0.8em;
  font-weight: 600;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.restrictAddressWidth {
  max-width: 31.5rem;
}

.lenderDropDown ul {
  font-family: var(--font-family);
  max-height: 20rem;
  overflow-y: auto;
}

.tempSpacing {
  padding-top: 2em;
}

.addressContainer {
  max-width: 31.5rem;
}

.traitContainer {
  column-count: 3;
  widows: 3;
  orphans: 3;
  gap: 1em;
  background-color: var(--pale-grey);
  padding: 2.5em;
  border-radius: 6px 6px 6px 6px;
  margin-bottom: 3em;
}

.traitItem {
  column-span: none;
  break-inside: avoid;
}
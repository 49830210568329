@import "../../components/shared/config.module.css";

.container {
  font-family: var(--font-family);
  margin-bottom: 5rem;
  color: var(--blue-black);
}

.inlineflex {
  display: inline-flex;
}

.flex {
  display: flex;
}

.center {
  align-items:center;
}

.leftpad6x {
  padding-left: 6em;
}

.leftpad2x {
  padding-left: 2em;
}

.toppad2x {
  padding-top: 2em;
}

.rightpad6x {
  margin-right: 6em;
}

.bottompad2x {
  padding-bottom: 2.5em !important;
}

.leftalign {
  text-align: left;
}

.rightalign {
  text-align: right;
}

.legalTermsIcon {
  width: 80px;
}

.headerText {
  display: flex;
  flex-direction: row;
  justify-content: left;
  color: var(--blue-black);
  font-size: 1.4em;
  font-family: var(--font-family);
  padding-top: 1.8rem;
}

.user {
  font-weight: 300;
  text-transform: uppercase;
}

.headingContainer {
  height: 60.9px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #01033b;
  display: flex;
  background-color: transparent;
  align-items: center;
  border: 0;
}

.caseDetailsCompleted path {
  fill: var(--light-blue);
}

.completedIcon {
  width: 82px;
  height: 82px;
  display: inline-flex;
  padding-top: 15px;
}

.giIcon {
  width: 40px;
  height: 40px;
  display: inline-flex;
}

.giHeader {
  font-size: 1.2rem;
}

.box {
  background-color: #fafafa;
  padding-top: 15px;
  padding-bottom: 15px;
  border: none;
}

.contentText {
  height: 97.4px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #01033b;
}

.lawfirmLogo {
  width: 228.1px;
  margin-top: 2rem;
}

.lawFirmDetails {
  text-align: left;
  height: 47.4px;
}

@import "../../../shared/config.module.css";

.email,
.mobile {
  width: 20em;
  margin-bottom: 1em;
}

.legend {
  font-family: var(--font-family);
  font-size: 1em;
  margin-bottom: 1em;
}

.label {
  margin-bottom: 0em;
}

@import "../../components/shared/config.module.css";

.icon {
    width: 300px;
    height: 300px;
}

.accordion {
    max-width: 25%;
    width: 25%;
}

.details {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
}

.header {
    margin-top: 3rem;
    font-family: var(--font-family);
    font-size: 36px;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
    color: var(--light-blue);
    text-transform: uppercase;
}

.subheader {
    margin-top: 3rem;
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: var(--blue-black);
}

.button {
    margin-top: 2rem;
    width: 200px;
    height: 40px;
    margin-bottom: 5rem;
    border-radius: 20px;
    background-color: var(--blue-black);
}
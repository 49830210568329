
.header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-transform: uppercase;
    background-color: transparent;
    text-align: left;
    color: var(--blue-black);
    font-size: 1.4em;
    font-weight: 300;
    font-family: var(--font-family);
    margin-bottom: 1em;
    border: 0;
}

.instructions {
  text-align: left;
  padding-bottom: 1em;
}

.tableContainer {
  background-color: var(--pale-grey);
  padding : 1.2em;
  width: 100%;
  margin-bottom: 3em;
}
.flexcontainer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
    width: 15em;
    text-align: center;
    outline-style: none;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .flexitem {
    cursor: pointer;
    color: #01033b;
    font-family: Montserrat;
    font-weight: 300;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }

  
.leftpad4x {
    padding-left: 3em;
  }
  
  .leftpad6x {
    padding-left: 6em;
  }
  
  .leftpad7x {
    padding-left: 7em;
  }
  
  .leftpad125x {
    padding-left: 1.25em;
  }
  
  .rightpad6x {
    margin-right: 6em;
  }

  .toppad1x {
    padding-top: 1em;
  }
  
  .toppad2x {
    padding-top: 2em;
  }
  
  .bottompad1x {
    padding-bottom: 1em !important;
  }

  .bottompad2x {
    padding-bottom: 2em !important;
  }
  
  .bottompad5x {
    padding-bottom: 5em !important;
  }
  
  .leftalign {
    text-align: left;
  }
  
  .rightalign {
    text-align: right;
  }
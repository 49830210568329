@import "../shared/config.module.css";

  .contentText {
    height: 97.4px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--blue-black);
  }

  .logo {
      height: 55px;
  }

  .toppad1x {
    composes: toppad1x from "../shared/layout.module.css"
  }

  .toppad2x {
    composes: toppad2x from "../shared/layout.module.css"
  }

  .icon {
    width: 82px;
    height: 82px;
    display: inline-flex;
    padding-top: 15px;
  }

  .flex {
    display: flex;
  }
  
  .center {
    align-items:center;
  }

  .box {
    background-color: var(--white-smoke);
    padding-top: 15px;
    padding-bottom: 15px;
    border: none;
  }

  .contact {
    text-align: left;
    height: 47.4px;
  }
.header {
    border-bottom: 1px solid var(--light-blue);
    padding-bottom: 1rem;
  }
  
  .table {
    width: 100%;
  }
  
  .tr {
    border-bottom: 2px solid var(--pale-grey);
  }
  
  .th {
    max-width: 16.66666%;
    font-weight: 300;
    text-align: left;
    padding-left: 15px;
    padding-right: 0px;
    font-family: var(--font-family);
    font-size: 1rem;    
  }
  
  .td {
    padding-top: 0;
    max-width: 16.66666%;
    font-weight: 300;
    text-align: left;
    padding-left: 15px;
    padding-right: 0px;
    font-family: var(--font-family);
    font-size: 1rem;
  }
  
  .td:first-child,
  .td:last-child {
    max-width: 8.333333%;
    padding-right: 15px;
  }
  
  .favouriteImage {
    width: 2rem;
    height: 2rem;
    padding: 0;
  }
  
  .checkbox input {
    height: 1.5rem;
    width: 1.5rem;
    color: var(--blue-black);
  }
  
  .link {
    display: block;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .totalCost {
    font-weight: 700;
  }
  
  .lawFirmPlacement {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-right: 2rem;
  }
  
  .logo {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 0.6rem;
  }
  
  .name {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-bottom: 1rem;
  }
  
  .lawFirmLogo {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 1rem;
    height: 50px;
  }

  .notificationText {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-bottom: 1rem;
    color: var(--pink);
    font-size: 0.8rem;
  }
  
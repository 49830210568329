@import "../../shared/config.module.css";

.hide {
  display: none;
}

.error {
  font-family: var(--font-family);
  color: var(--negative);
  font-weight: 600;
  transform: translateY(40px);
}

.toggleButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px !important;
  background-color: var(--pale-blue);
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 1em;
  color: var(--blue-black);
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  border: 0px;
  margin-top: 3em;
}

.flexcontainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  text-align: center;
  outline-style: none;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 3em;
}

.flexitem {
  height: 10em;
  width: 12em;
  cursor: pointer;
  color: var(--blue-black);
  font-family: var(--font-family);
  font-weight: 300;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.spacer::after {
  content: "";
  font-size: 0.9em;
  margin: 0 1em;
}

.radioImage {
  width: 5em;
  height: 5em;
}

.hiddenradio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
.hiddenradio [type="radio"] + img {
  cursor: pointer;
}

/* CHECKED STYLES */
.hiddenradio [type="radio"]:checked + img {
  outline: 2px solid var(--pink);
}

.label {
    font-family: var(--font-family);
    font-size: 1em;
    color: var(--blue-black);
    font-weight: 300;
    padding-bottom: 0.75em;
    padding-top: 2em;
}

.input {
    font-family: var(--font-family);
    font-size: 1em !important;
    background-color: white !important;
    color: var(--blue-black);
    font-weight: 300;
}

.error {
    color: var(--negative);
    size: 0.8em;
    font-weight: 600;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

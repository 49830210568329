.text-primary {
    font-size: 1rem;
    line-height: 1.2;
    text-align: left;
 }
 
 .text-secondary {
    font-size: 0.875rem;
    text-align: left;
 }
 
 .dialog {
    display: flex;
    justify-content: center;
 }
 
 .dialog-content {
    min-width: 42.5rem;
    padding: 1.5rem;
 }
 
 .dialog-body {
    padding: 0 1rem;
 }
 
 .save-quote-form {
    width: 100%;
    margin-top: 0.875rem;
 }
 
 .address-search {
    font-size: 1rem !important;
 }

 p, strong {
     text-align: left;
 }

 strong {
     font-weight: 600;
 }
 
 .primarybutton {
   margin-left: 1rem;
   font-size: 0.9em;
  }

 .container {
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   margin-bottom: 1.875rem;
}
@import "../../components/shared/config.module.css";

.bmBurgerButton {
  position: absolute;
  width: 41px;
  height: 29px;
  left: 28px;
  top: 1.6em;
}

.bmCrossButton {
  position: fixed;
  height: 29px;
  width: 29px;
  margin-top: 1.6em;
  margin-right: 305px;
}

/*Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bmMenu {
  background: var(--white);
  padding: 2.5em 0em 0;
  font-size: 1.15em;
  margin-top: -3.6em;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
}
.bm-overlay {
  background: rgba(0, 0, 0, 0.1);
}

.burgerIcon {
  width: 40px;
}

.crossIcon {
  width: 40px;
}

.outerMenuContainer {
  background-color: var(--white);
}

.menuContent {
  display: flex;
  flex-direction: row;
  background-color: var(--white);
  justify-content: left;
  text-align: left;
  padding-top: 4em;
  margin-left: 2em;
  margin-right: 2em;
}

.menuContent:focus {
  outline: none;
}

.subHeader {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: left;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 0.8em;
  text-align: left;
  color: var(--blue-black);
  margin-bottom: 1em;
}

.menuItem {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: left;
  font-family: var(--font-family);
  font-weight: light;
  font-size: 0.8em;
  text-align: left;
  color: var(--blue-black);
}

.menuItemText {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  color: var(--blue-black);
  width: 100%;
  padding: 10px 0;
  border: 0px;
  background-color: var(--white);
}

.menuItemText:focus,
.menuItemText:active,
.menuItemText:not(:disabled):not(.disabled):active:focus {
  outline: none;
  background-color: transparent;
  color: var(--blue-black);
  box-shadow: none;
  border-color: transparent;
}

.menuItemText:hover {
  text-decoration: underline;
  background-color: transparent;
  color: var(--blue-black);
}

.menuItemCount {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: var(--blue-black);
  min-width: 30px;
  justify-content: right;
  text-align: right;
}

.spacer {
  display: inline-block;
  height: 0.8em;
}

.buttonText {
  min-width: 180px;
}

.buttonIcon {
  position: relative;
  right: auto;
  top: auto;
}
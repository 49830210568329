.addButtonContainer {
  display: flex;
  justify-content: flex-start;
}

.addButton {
  min-width: 15rem;
}

.removeButton {
  min-width: 15rem;
}

.customerFormContainer {
  margin: 0 1.25rem;
}

.customerFormContainer > div {
  margin: 2rem 0;
  width: 100%;
}

.infoText {
  display: flex;
  justify-content: flex-start;
}

@import "../../components/shared/config.module.css";

.viewCaseSummaryContainer {
  font-family: var(--font-family);
  text-align: left;
  margin-bottom: 2rem;
}

.pageHeader {
  color: var(--blue-black);
  font-size: 1.4em;
  font-family: var(--font-family);
  padding-left: 4.06rem;
}

.spinner {
  font-family: var(--font-family);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-right: 20px;
}

.loading {
  font-family: var(--font-family);
  display: flex;
  justify-content: center;
  padding: 20px;
}
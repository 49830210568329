.header {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-transform: uppercase;
  background-color: transparent;
  text-align: left;
  color: var(--blue-black);
  font-size: 1.4em;
  font-weight: 300;
  font-family: var(--font-family);
  margin-bottom: 1em;
  border: 0;
}

.tableContainer {
  background-color: var(--pale-grey);
  width: 100%;
  margin-bottom: 3em;
}

.actionButton {
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  color: var(--blue-black);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  padding: 0.5rem 0.75rem;
}

.actionButton:hover,
.actionButton:focus {
  background-color: var(--white);
  border: 1px solid var(--blue-black);
  color: var(--blue-black);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  padding: 0.5rem 0.75rem;
}

.addNewProductButton {
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  color: var(--blue-black);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  padding: 0.5rem 0.75rem;
}

.addNewProductButton:hover,
.addNewProductButton:focus {
  background-color: var(--white);
  border: 1px solid var(--blue-black);
  color: var(--blue-black);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  padding: 0.5rem 0.75rem;
}

.buttonColumn {
  //align-items: right;
}

@import "../../shared/config.module.css";

.checkbox {
  margin: 10px !important;
  background-color: none;
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 1em;
  color: var(--blue-black);
  display: initial;
}

.checkbox input {
  position: static !important;
  margin-left: -1.25rem;
  margin-right: 1rem;
  width: 1.2rem;
  height: 1.4rem;
}

.checkbox label {
  vertical-align: top;
  padding-top: 0.3em;
}

.infoIcon {
  margin-top: -1em;
}

.flexcontainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  text-align: center;
  outline-style: none;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flexitem {
  cursor: pointer;
  color: var(--blue-black);
  font-family: var(--font-family);
  font-weight: 300;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.accordionContainer {
  display: flex;
  width: 100%;
}

.accordion {
  width: 100%;
}

.accordionBody {
  width: 100%;
  max-width: 45rem;
}

.accordionHeader {
  background-color: var(--pale-grey);
  border: 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 2.25rem;
}

.completedIcon {
  width: 2.25rem;
  margin-right: 1rem;
}

.headingAndIconContainer {
  display: flex;
  align-items: center;
}

.iconButton {
  height: 36px;
  width: 36px;
}

.iconButtonWrapper {
  margin-left: 5px;
}

.errorCell {
  position:relative;
  top: -1rem;
  color: red;
  font-size: 0.6rem;
  font-weight: 400;
  font-family: var(--font-family);
  text-align: left;
}

.dirtyCell {
  border-color: orange;
}

.dirtyErrorCell {
  position:relative;
  top: -1rem;
  color: orange;
  font-size: 0.6rem;
  font-weight: 400;
  font-family: var(--font-family);
  text-align: left;
}

.form-control.is-invalid{
  background-image: none;
}

.headerRowContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 8px;
  width: 100%;
  text-align: left;
}
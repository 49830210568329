:root {
  --dark-blue: #03296a;
  --pale-blue: #d5eef7;
  --pale-grey: #f4f4f4;
  --blue-black: #01033b;
  --pink: #d41c78;
  --orange: #ff5f00;
  --pale-pink: #f2cee0;
  --purple: #9e4db6;
  --pale-purple: #e8d7ec;
  --white: #fff;
  --grey-black: #333;
  --green: #99c161;
  --pale-green: #dde9cc;
  --grey: #666;
  --light-grey: #cccccc;
  --light-blue: #00aae5;
  --white-smoke: #fafafa;
  --font-family: "Montserrat", "Trebuchet MS", "Lucida Sans Unicode",
    "Lucida Grande", "Lucida Sans", Arial, sans-serif;

  --disabled-btn: #00aae5;

  --positive: #99c161;
  --negative: #d41c78;
    
}
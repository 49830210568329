.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.875rem;
}

.labelContainer {
  display: flex;
}

.infoText {
  margin-left: 0.25rem;
  margin-top: 0.1rem;
}

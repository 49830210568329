@import "../../components/shared/config.module.css";

.container {
  font-family: var(--font-family);
  margin-bottom: 5rem;
}

.formColumn > div {
  margin: 1.25rem 0;
}

.headingHighlight {
  font-weight: 700;
  margin: 1.25rem;
}

.instructHeading {
  padding-left: 0;
}

.saveButtonContainer {
  margin-top: 3.75rem;
}

.summaryColumn {
  margin: 0;
  padding: 0;
}

.summaryContainer {
  background-color: var(--pale-grey);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 1.25rem 0 0 1.25rem;
}

.summaryHeading {
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
  margin: 1.25rem 1rem;
}

.caseDetailsCompleted path {
  fill: var(--light-blue);
}

.propertyCompletedIcon path {
  fill: var(--purple);
}

.customerCompletedIcon path {
  fill: var(--pink);
}

.lenderCompletedIcon path {
  fill: var(--green);
}

.additionInformationCompletedIcon path {
  fill: var(--light-blue);
}

.insuranceCompletedIcon path {
  fill: var(--orange);
}

.summaryEdit {
  font-size: 0.75rem;
  background-color: transparent;
  color: var(--blue-black);
  border: 2px solid var(--light-blue);
  border-radius: 999px;
  width: 5rem;
  height: 1.5rem;
  line-height: 0.15rem;
  padding: 0.5rem;
}

.submittedCustomer {
  border: 2px solid var(--light-blue);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 2.25rem;
  margin: 1.25rem 1rem;
}

.submittedCustomerButton {
  font-size: 0.75rem;
  background-color: transparent;
  color: var(--blue-black);
  border: 2px solid var(--light-blue);
  border-radius: 999px;
  width: 5rem;
  height: 1.5rem;
  line-height: 0.15rem;
  padding: 0.5rem;
  margin-right: 0.25rem;
}

.customerSectionButtonContainer {
  margin-left: 1rem;
}

.addNewCustomerButton {
  min-width: 15rem;
}

.summaryCustomerEditButton {
  margin-bottom: 1rem;
}

.error {
  color: var(--negative);
  text-align: left;
  margin-bottom: 1rem;
  margin-left: 1rem;
  font-weight: 700;
}

.lenderDropDown ul {
  max-height: 20rem;
  overflow-y: auto;
}

.lenderContainer {
  padding: 1rem;
}

.lenderContainer > div {
  margin-bottom: 2.5rem;
}
.lenderContent {
  width: 100%;
  text-align: left;
  margin-top: 1.25rem;
}

.completeLenderButton {
  width: 12rem;
}

.submitControls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.readyToSubmitMessageHeading {
  padding: 0;
  margin-bottom: 2.5rem;
}

.spinner {
  position: relative;
  width: 26px;
  height: 26px;
  margin-right: 20px;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.sectionRow {
  margin-bottom: 40px;
}

.uinsureimage {
  height: 60px;
  float: left;
}
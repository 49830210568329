.tableHeader {
    border-bottom: 2px solid var(--light-blue);
}

.tableHeaderText {
    font-size: 1rem;
    font-weight: 700;
    margin-left: 2.5rem;
}

.tableBody {
    padding: 1rem 2.5rem;
}

.tableBody td {
    padding: 0.5rem 0rem;
}

.tableValueColumn {
    text-align: right;
}

@media (min-width: 768px) {
    .tableBody {
        width: 42vw;
    }
}

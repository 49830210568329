.printCopy {
    display: none;
}

@media print {
    .printCopy {
        display: block;
    }
    
    .h2 {
        font-size: 14px;
        font-weight: 700;
    }

    .h3 {
        font-size: 12px;
        font-weight: 700;
    }

    .p {
        font-size: 12px;
    }
}

@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "./print.css";

body {
  padding-top: 0px;
  color: var(--dark-blue);
  text-align: center;
}

h1 {
  background-color: var(--blue-black);
  border: 0.1rem solid var(--blue-black);
  color: var(--white);
  text-align: center;
}

/* Override certain bootstrap classes */
.btn-primary {
  background-color: var(--blue-black);
  color: var(--white);
  border-radius: 1.5rem;
  min-width: 10em;
}

.btn-primary:hover {
  background-color: var(--dark-blue);
  color: var(--white);
}

.btn-primary:disabled {
  background-color: var(--disabled-btn);
  box-shadow: none;
}
.btn-primary:disabled:hover {
  background-color: var(--disabled-btn);
  box-shadow: none;
  border-color: transparent;
}

.btn-secondary {
  color: var(--blue-black);
  border: 0.1rem solid var(--grey);
  background-color: var(--white);
}

.btn-secondary:hover:not(.active) {
  background-color: var(--blue-black);
  color: var(--white);
}

.btn-tertiary {
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  color: var(--blue-black);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  min-width: 7em;
}

.btn-link-arrow {
  padding: 0;
}

.btn-link-arrow:hover:not(:disabled) .link-arrow--text {
  text-decoration: underline;
}

.btn-tertiary:hover:not(:disabled) {
  border-color: var(--blue-black);
}

.btn-tertiary.btn-tertiary:disabled:hover {
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  color: var(--blue-black);
}

.btn-md {
  padding: 0.2rem 1.25rem;
}

.active {
  background-color: var(--blue-black) !important;
  color: var(--white);
}

.rdg-cell {
  float: left;
  padding: 1rem;
}

.toast .close {
  color: var(--white);
  text-shadow: none;
  opacity: 1;
}

.toast .close:hover {
  opacity: .5;
}

.toast .close:focus {
  border: none;
  outline: none;
}

.form-check-custom input {
  margin-top: 0;
  position: relative;
  margin-left: -1.25rem;
  margin-right: 1rem;
  width: 1.2rem;
  height: 1.4rem;
  display: inline-block;
  vertical-align: middle;
}

.form-check-custom  label {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 1em;
  color: var(--blue-black);
  display: inline-block;
  vertical-align: middle;
}

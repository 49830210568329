.dob {
    padding-top: 0;
}

.h1 {
    composes: h1 from "../../components/shared/typography.module.css";
}

.actionContainer {
    padding: 2em 0;
    text-align: left;
}

.firstButton {
    margin-right: 20px;
}

.loading {
    composes: loading from '../../components/shared/loading.module.css'
}

.spinner {
    composes: spinner from '../../components/shared/loading.module.css'
}
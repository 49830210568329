.quoteTypeBanner {
    display: flex;
    align-items: center;
    height: 3.5rem;
    margin: 2rem 0;
    background-color: var(--white-smoke);
    border-radius: 5px;
}

.quoteTypeBannerIcon {
    width: 2rem;
}

.quoteTypeBanner > div {
    margin-left: 0.75rem;
}

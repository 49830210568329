.loading {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.spinner {
    position: relative;
    width: 26px;
    height: 26px;
    margin-right: 20px;
}
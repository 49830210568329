.expander {
  width: 50px;
  flex-shrink: 0;
  align-self: center;
}

.rowContainer {
  display: flex;
  height: 100%;
  padding: 15px;
  border-bottom: 0.1rem solid #dee2e6;
  gap: 5px;
}

.rowContainer > div {
  border-radius: 5px;
  padding: 5px;
  align-items: center;
}

.row {
  flex-grow: 1;
  text-align: left;
}

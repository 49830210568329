.text-primary {
   font-size: 1rem;
   line-height: 1.2;
   text-align: left;
}

.text-secondary {
   font-size: 0.875rem;
   text-align: left;
}

.dialog {
   display: flex;
   justify-content: center;
}

.dialog-content {
   min-width: 42.5rem;
   padding: 1.5rem;
}

.dialog-body {
   padding: 0 1rem;
}

.save-quote-form {
   width: 100%;
   margin-top: 0.875rem;
}

.address-search {
   font-size: 1rem !important;
}
.summarySectionContainer {
  font-size: 0.75rem;
  padding-bottom: 1.25rem;
}

.completedIcon {
  width: 1.25rem;
}

.caret {
  width: 0.75rem;
}

.heading {
  margin-left: 0.75rem;
  text-align: left;
}

.headingContainer {
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border: 0;
}

.headingTextAndIconContainer {
  display: flex;
  align-items: center;
}

.summarySectionBody {
  padding: 1.25rem 1rem 0 3rem;
  text-align: left;
}

.summarySectionBody > div {
  margin-bottom: 1rem;
}

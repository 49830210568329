@import "../../components/shared/config.module.css";

.container {
  font-family: var(--font-family);
  margin-bottom: 5rem;
  color: var(--blue-black);
}

.inlineflex {
  display: inline-flex;
}

.leftpad6x {
  padding-left: 6em;
}

.rightpad6x {
  margin-right: 6em;
}

.bottompad2x {
  padding-bottom: 2.5em !important;
}

.leftalign {
  text-align: left;
}

.rightalign {
  text-align: right;
}

.legalTermsIcon {
  width: 80px;
}

.headerText {
  display: flex;
  flex-direction: row;
  justify-content: left;
  color: var(--blue-black);
  font-size: 1.4em;
  font-family: var(--font-family);
  padding-top: 1.8rem;
}

.contentText {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-family: var(--font-family);
  font-size: 1em;
  font-weight: 300;
  padding-bottom: 2em;
}

.subheader {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-family: var(--font-family);
  font-size: 1em;
  font-weight: 700;
  margin: 2em 0;
}

.checkbox {
  padding-left: 5px;
  margin-bottom: 2em;
}

.buttonAlignment {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  padding: 0;
  max-width: 300px;
  margin-bottom: 2em;
}

.sectionHeader {
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 1em;
  text-decoration: underline;
}

.sectionText {
  margin-bottom: 1em;
}

.sectionLink {
  color: var(--light-blue);
  text-decoration: underline;
}

.sectionBold {
  font-weight: 700;
}

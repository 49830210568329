.container {
  display: flex;
  align-items: center; 
  gap: 1em;
  padding: 1em;
  width: 100%;
}

.textContainer {
  flex: 20; 
  display: flex;
  flex-direction: column;
  gap: 1em;
  color:  #01033B;
  text-align: left;
}

.userText {
  font-weight: 300; 
  font-size: 24px;

}

.text {
  font-weight: 300; 
  font-size: 16px;
}


.image {
  flex: 80; 
  display: flex;
  justify-content: center;
}

.image img {
  width: 100%;
  height: auto;
}

@media (max-width: 1275px) {
  .image {
    display: none;
  }
}

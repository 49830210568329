@import "../../components/shared/config.module.css";

.flexcontainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flexitem {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.footer,
.footer:visited {
  background-color: var(--white); /* IE 11 */
  background-color: var(--white);
  border-top: 1px solid var(--light-blue); /* IE 11 */
  border-top: 1px solid var(--light-blue);
  text-align: left;
  padding: 20px 0px 20px 0px;
  /* padding-left: 6em; */
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 0.8em;
  /* position: fixed; */
  left: 0;
  bottom: 0;
  height: 60px;
  width: 100%;
  color: var(--blue-black);
  z-index: 99;
}

a,
a:hover {
  color: var(--blue-black);
}

.pipe::after {
  content: "|";
  color: var(--blue-black);
  font-size: 0.9em;
  margin: 0 0.5em;
}

.copyright {
  color: var(--blue-black);
  font-size: 0.95em;
  margin: 0 1em 0 0;
}

.spacer::after {
  content: "";
  font-size: 0.9em;
  margin: 0 1.4em;
}

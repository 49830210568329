@import "../../components/shared/config.module.css";

.signin,
.signin:visited {
  background-color: var(--blue-black);
  border: 1px solid var(--blue-black);
  color: var(--white);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  line-height: 1.4em;
  padding: 0;
  height: 3em;
  width: 20em;
}

.signin:hover {
  background-color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
}

.createaccount {
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  color: var(--blue-black);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  padding: 0;
  height: 3em;
  width: 20em;
}

.createaccount:hover,
.createaccount:focus {
  background-color: var(--white);
  border: 1px solid var(--blue-black);
  color: var(--blue-black);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  padding: 0;
  height: 3em;
  width: 20em;
}

.inlineflex {
  display: inline-flex;
  
}

.leftpad6x {
  padding-left: 6em;
}

.rightpad6x {
  margin-right: 6em;
}

.bottompad2x {
  padding-bottom: 2em !important;
}

.bottompad1x {
  padding-bottom: 1em !important;
}

.leftalign {
  text-align: left;
}

.rightalign {
  text-align: right;
}

.landingtitle {
  font-family: var(--font-family);
  font-size: 2.5em;
  color: var(--blue-black);
  font-weight: 300;
  line-height: 1.2em;
}

.landingtext {
  font-family: var(--font-family);
  font-size: 1.2em;
  color: var(--blue-black);
  font-weight: 300;
}

.videosection {
background-color: var(--pale-grey);
height: 100%;
margin-left: 40px;
margin-right: 0px;
padding: 40px;
border-radius: 6px;
font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
}

.uinsurelogo {
  width: 100px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.videolink {
  padding-bottom: 0.8em !important;
}

.videolink a {
  color: var(--purple);
}

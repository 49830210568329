.container {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 8px;
  text-align: left;
  width: 100%;
}

.iconButton {
  height: 36px;
  width: 36px;
}

.iconButtonWrapper {
  margin-left: 5px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
}

.textField {
  margin-top: auto;
  margin-bottom: auto;
}
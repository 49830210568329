.categoryContainer {
  padding: 2rem;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-transform: uppercase;
  background-color: transparent;
  text-align: left;
  color: var(--blue-black);
  font-size: 1.4em;
  font-weight: 300;
  font-family: var(--font-family);
  margin-bottom: 1em;
  border: 0;
}

.subheader {
  display: flex;
  flex-direction: row;
  justify-content: left;
  background-color: transparent;
  text-align: left;
  color: var(--blue-black);
  font-size: 1em;
  font-weight: 100;
  font-family: var(--font-family);
  margin-bottom: 1em;
  border: 0;
}

.categoryHeader {
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-family);
}

.separator {
  margin: 10px 0;
  border-color: var(--blue-black);
}

.label {
  padding-left: 0.5em;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-family);
}

.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide browser default checkbox (so our custom one looks boss) */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.customCheck {
  position: absolute;
  top: 8px;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: var(--white);
  border-style: solid;
  border-width: 1px;
  border-color: #666666;
}

.checkboxContainer:hover input ~ .customCheck {
  background-color: #ccc;
}

.checkboxContainer input:checked ~ .customCheck {
  background-color: var(--blue-black);
}

.customCheck:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxContainer input:checked ~ .customCheck:after {
  display: block;
}

.checkboxContainer .customCheck:after {
  left: 10px;
  top: 0px;
  width: 10px;
  height: 25px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.buttonContainer {
  padding-left: 1em;
  margin-bottom: 1em;
}

.saveButton {
  background-color: var(--blue-black);
  border: 1px solid var(--light-blue);
  color: var(--white);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  padding: 0.75rem 2rem;
}

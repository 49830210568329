.tableRow {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 8px;
}

.priceElement {
  height: 46px;
}

input[type="checkbox"] {
  width: 1.2em;
  height: 1.2em;
}

.internationalContainer {
  position: relative;
  margin-bottom: 1em;
  height:46px;
}

.internationalCheck {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.iconButton {
  height: 36px;
  width: 36px;
}

.iconButtonWrapper {
  margin-left: 5px;
}

.bandInputError {
  margin-bottom: 1rem;
}

.bandInputError input {
  border-color: red;
  color: red;
}

.bandDirty input {
  border-color: orange;
}

.bandInputMutating input {
  background-color: #e9ecef;
  pointer-events: none;
}

.bandInputError label {
  color: red;
}

.errorCell {
  position:relative;
  top: -1rem;
  color: red;
  font-size: 0.6rem;
  font-weight: 400;
  font-family: var(--font-family);
  text-align: left;
}

.dirtyCell {
  position:relative;
  color: var(--orange);
  font-size: 0.6rem;
  font-weight: 400;
  font-family: var(--font-family);
  text-align: left;
}

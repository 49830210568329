.headerRow {
  text-align: left;
  padding: 1em;
  border-bottom:1px solid rgb(1, 3, 59);
}

.limitInput {
  margin-bottom: 0;
}

.disabledLimitInput input {
  background-color: #e9ecef;
  pointer-events: none;
}

.container {
  max-width: 100%;
  background-color: var(--pale-grey);
}

.labelCell {
  text-align: left;
}

.itemRow {
  padding: 1em;
  border-bottom:1px solid rgb(1, 3, 59);
}

.headerCell {
  border-bottom: 2px solid #000; 
}

.rowCell {
  border-bottom: 1px solid #000; 
  width: 50%;
}

.limitErrorRow {
  color : #D41C78;
  font-size: smaller;
}

.submitButton {
  margin-top: 1em;
}

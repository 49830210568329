.loadingSpinner {
    position: relative;
    width: 15vw;
    height: 30px;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
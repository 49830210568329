.paginationContainer {
  display: flex;
  align-items:flex-end;
  flex-direction: row;
  align-content: flex-end;
}

.paginationContainer button:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.paginationContainer button:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.paginationItem {
  border: 1px solid var(--grey);
  background-color: var(--white);
  padding: 0 16px;
  height:44px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  line-height: 1.43;
  font-size: 16px;
  font-family: var(--font-family);
  min-width: 38px;
}

.dots:hover {
  background-color: transparent;
  cursor: default;
}

.paginationItem:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.selected {
  background-color: var(--pale-purple);
}

.arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.arrow::before {    
    position: relative;
    top: 1pt;
    content: '';
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
  }

  .arrowLeft {
    transform: rotate(-135deg) translate(-50%);
  }

  .arrowRight {
    transform: rotate(45deg);
  }
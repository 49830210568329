@import "../../components/shared/config.module.css";

.inlineflex {
  display: inline-flex;
}

.leftpad6x {
  padding-left: 6em;
}

.rightpad6x {
  margin-right: 6em;
}

.bottompad2x {
  padding-bottom: 2.5em !important;
}

.leftalign {
  text-align: left;
}

.rightalign {
  text-align: right;
}

.brokerIcon {
  width: 82px;
  height: 82px;
  margin: 0 25px 30.2px 0;
}

.welcomeUser {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-size: 1.4em;
  font-weight: 500;
  font-family: var(--font-family);
  margin-bottom: 1em;
}

.user {
  font-weight: 900;
  padding-left: 0.4em;
  text-transform: uppercase;
}

.welcomeText {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-size: 1em;
  font-weight: 300;
  font-family: var(--font-family);
}

.emulateSection {
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  background-color: var(--pale-grey);
  border-radius: 6px;
  padding: 2.5em;
  margin-top: 2rem;
}

.emulateButton {
  text-align: left;
  color: var(--blue-black);
  background-color: var(--pale-grey);
  border: 0px;
  width: 100%;
  margin-top: 1rem;
}

.modalContent {
  padding: 2rem;
}

.modalHeader {
  border-bottom: none;
}

.feedback {
  color: var(--pink);
  font-size: 80%;
}

.emulateModalFindButton {
  font-family: var(--font-family);
  font-weight: 300;
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  color: var(--blue-black);
  border-radius: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
  padding: 0 45px;
  height: 2rem;
  margin: 5.6px 2.2px 0 2px;
}

.emulateModalSecondaryButton {
  font-family: var(--font-family);
  font-weight: 300;
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  color: var(--blue-black);
  border-radius: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.4rem;
  padding: 0 45px;
  height: 2.75rem;
  min-width: 11.5rem;
}

.emulateModalSecondaryButton:hover .emulateModalFindButton:hover {
  background-color: var(--white);
  border: 1px solid var(--blue-black);
  color: var(--blue-black);
}

.emulateModalPrimaryButton {
  font-family: var(--font-family);
  font-weight: 300;
  background-color: var(--blue-black);
  border: 1px solid var(--blue-black);
  color: var(--white);
  border-radius: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.4rem;
  padding: 0;
  height: 2.75rem;
  width: 11.5rem;
}

.emulateModalPrimaryButton:hover {
  background-color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
  color: var(--white);
}

.actionButtons {
  padding-left: 0;
}

.actionButtonsContainer {
  padding-top: 1rem;
}

.emulateModalAction {
  grid-auto-rows: minmax(min-content, max-content);
}

.emulateModalLabel {
  text-align: left;
  width: 18.75rem;
  grid-auto-rows: minmax(min-content, max-content);
}

.emulateButtonWrapper {
  padding-top: 30px;
}

.emulateResult {
  text-align: left;
}

.emulateResultRow {
  margin-bottom: 0;
}

.displayValueWidth {
  width: calc(100% + 15px);
}

.emulateResultForm {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.actionSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: var(--white);
  font-family: var(--font-family);
}

.newQuoteCard,
.savedQuoteCard,
.checkProgressCard {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  background-color: var(--pale-grey);
  width: 100%;
  border-radius: 6px;
  margin-top: 2.5em;
}

.customGutter {
  padding: 0px 20px;
}

.actionSectionContent {
  display: flex;
  flex-direction: column;
  padding: 2.5em 2.5em 0em 2.5em;
}

.actionSectionIcon {
  width: 80px;
}

.actionSectionText {
  padding-top: 2.5em;
  color: var(--blue-black);
  min-height: 120px;
}

.actionSectionLink {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2.5em;
  margin-top: 2.5em;
  color: var(--blue-black);
}

.actionSectionLinkText {
  text-align: left;
  color: var(--blue-black);
  background-color: var(--pale-grey);
  border: 0px;
  width: 100%;
  padding-right: 30px;
}

.actionSectionLinkText:hover {
  color: var(--dark-blue);
  text-decoration: underline;
  background-color: transparent;
}

.actionSectionLinkText:focus,
.actionSectionLinkText:active,
.actionSectionLinkText:not(:disabled):not(.disabled):active:focus {
  outline: none;
  background-color: transparent;
  color: var(--blue-black);
  box-shadow: none;
  border-color: transparent;
}

.newQuoteBottomBorder {
  display: flex;
  flex-direction: column;
  background-color: var(--purple);
  height: 0.6em;
  border-radius: 0px 0px 6px 6px;
  margin-top: 2.5em;
}

.savedQuoteBottomBorder {
  display: flex;
  flex-direction: column;
  background-color: var(--light-blue);
  height: 0.6em;
  border-radius: 0px 0px 6px 6px;
  margin-top: 2.5em;
}

.checkProgressBottomBorder {
  display: flex;
  flex-direction: column;
  background-color: var(--pink);
  height: 0.6em;
  border-radius: 0px 0px 6px 6px;
  margin-top: 2.5em;
}

.dashboardSpacer {
  display: inline-block;
  width: 2.5em;
}

.prefsSection {
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;

  justify-content: left;
  text-align: left;
  background-color: var(--pale-grey);
  border-radius: 6px 6px 6px 6px;
  padding: 2.5em 2.5em 2.5em 2.5em;
}

.prefsSectionNav {
  display: flex;
  flex-direction: column;
}

.prefsSectionIcon {
  width: 80px;
}
.prefsSectionLink {
  display: flex;
  flex-direction: row;
  margin-top: 2.5em;
  padding-right: 2.5em;
  justify-content: space-between;
}

.prefsSectionLinkText {
  display: flex;
  flex-direction: row;
  color: var(--blue-black);
}

.prefsSectionLinkText:hover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--dark-blue);
}

.prefsSectionContent {
  display: flex;
  flex-direction: column;
  color: var(--blue-black);
  justify-content: center;
  align-items: left;
}

.pricingSection {
  font-family: var(--font-family);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  text-align: left;
  background-color: var(--pale-grey);
  border-radius: 6px 6px 6px 6px;
  padding: 2.5em 2.5em 2.5em 2.5em;
}

.pricingSectionNav {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.pricingSectionIcon {
  width: 80px;
}
.pricingSectionLink {
  display: flex;
  flex-direction: row;
  margin-top: 2.5em;
  padding-right: 2.5em;
  justify-content: space-between;
}

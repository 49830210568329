@import "../../components/shared/config.module.css";

.paginationContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.spinner {
    font-family: var(--font-family);
    position: relative;
    width: 26px;
    height: 26px;
    margin-right: 20px;
}

.loading {
    font-family: var(--font-family);
    display: flex;
    justify-content: center;
    padding: 20px;
}

.loadingCell {
    padding: 100px 0;
}

.table {
    width: 100%;
}

.title {
    composes: h1 from "../../components/shared/typography.module.css"
}

.tableHeader {
    font-family: var(--font-family);
    display: block;
    text-align: left;
    color: var(--blue-black);
    padding-bottom: 10px;
    border-bottom: 2px solid var(--light-blue);
    margin-bottom: 15px;
}

.columnHeader {
    font-family: var(--font-family);
    text-align: left;
    color: var(--blue-black);
    vertical-align: top;
}

.tableRow {
    font-family: var(--font-family);
    background-color: var(--pale-grey);
    border-bottom: 2px solid var(--white);
}

.td {
    text-align: left;
    padding: 1em 0px 1em 10px;
}

.td:first-child {
    padding-left: 1.8rem;
}
.td:last-child {
    padding-right: 1.8rem;
}

.button {
    margin: 0 5px 0 0;
}

.buttonCell {
    display: flex;
    align-items: center;
}

.quoteName {
    font-weight: 500;
    color: var(--purple);
    /* max-width: 250px; */
}

.quoteFirm {
    /* max-width: 250px;  */
    text-align: left;
    padding-left: 10px;
}

.quotePrice 
.quoteFee {
    font-weight: 500;
}

.expiry {
/* min-width: 110px; */
}

.bottompad2x {
    composes: bottompad2x from "../../components/shared/layout.module.css"
}

.bottompad1x {
    composes: bottompad1x from "../../components/shared/layout.module.css"
}
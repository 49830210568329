@import "../../components/shared/config.module.css";

body {
  padding-bottom: 100px !important;
}
.intro {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-size: 1.4em;
  font-weight: 300;
  font-family: var(--font-family);
  margin-bottom: 2em;
}

.paragraph {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-size: 1em;
  font-weight: 300;
  font-family: var(--font-family);
  margin-bottom: 1em;
}

.paragraphBold {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-size: 1em;
  font-weight: 500;
  font-family: var(--font-family);
  margin-bottom: 1em;
}

.label {
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  padding-bottom: 0.75em;
  padding-top: 2em;
}

.labelFirst {
  padding-top: 0;
}

.instruction {
  display: flex;
  flex-direction: row;
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  margin-bottom: 1em;
}

.toggleButton {
  margin-right: 10px !important;
  background-color: white;
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 1.2em;
  color: var(--blue-black);
}

.instructionImg {
  display: flex;
  flex-direction: row;
  height: 2.2em;
  width: 2.2em;
  padding-right: 1em;
}

.instructionAlign {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: 0em;
}

.instructionText {
  padding-top: 0.4em;
}

.stepInstruction {
  display: flex;
  flex-direction: row;
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--blue-black);
  font-weight: 300;
  background-color: var(--pale-grey);
  border-radius: 6px;
  height: 60px;
  margin-top: 2.5em;
}

.stepInstructionImg {
  height: 3em;
  width: 3em;
  padding-right: 1em;
  padding-top: 0.7em;
}

.stepInstructionAlign {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: 0em;
}

.stepInstructionText {
  padding-top: 1.2em;
}

.inlineflex {
  display: inline-flex;
}

.leftpad1x {
  padding-left: 1em;
}

.leftpad4x {
  padding-left: 3em;
}

.leftpad6x {
  padding-left: 6em;
}

.leftpad7x {
  padding-left: 7em;
}

.leftpad125x {
  padding-left: 1.25em;
}

.rightpad1x {
  padding-right: 1em;
}

.rightpad2x {
  padding-right: 2em;
}

.rightpad6x {
  margin-right: 6em;
}

.toppad2x {
  padding-top: 2em;
}

.bottompad1x {
  padding-bottom: 1em !important;
}

.toppad1x {
  padding-top: 1em !important;
}

.toppad03x {
  padding-top: 0.3em;
}

.bottompad2x {
  padding-bottom: 2em !important;
}
.bottommargin2x {
  margin-bottom: 2em !important;
}

.bottompad5x {
  padding-bottom: 5em !important;
}

.leftalign {
  text-align: left;
}

.rowtitle {
  font-family: var(--font-family);
  font-size: 2.5em;
  margin-left: 0.5em;
  vertical-align: middle;
}

.columntitle {
  font-size: 1rem;
  vertical-align: middle;
}

.pricebreakdown {
  font-size: 1rem;
  vertical-align: middle;
  padding-top: 3em;
  padding-left: 1em;
  text-decoration: underline;
}

.columndata {
  font-size: 2.3rem;
}

p span {
  display: block;
}

.rightalign {
  text-align: right;
}

.favouriteimage {
  width: 2rem;
  height: 2rem;
}

.fixedpriceimage,
.marketplaceimage {
  width: 5rem;
  height: 5rem;
}

.input {
  font-family: var(--font-family);
  font-size: 1em !important;
  background-color: white !important;
  color: var(--blue-black);
  font-weight: 300;
}

.traitsSection {
  background-color: var(--pale-grey);
  padding: 2.5em;
  border-radius: 6px 6px 6px 6px;
  margin-bottom: 3em;
}

.checkbox input {
  height: 1.5rem;
  width: 1.5rem;
  color: var(--blue-black);
}

.resultheader {
  font-family: var(--font-family);
  font-size: 1rem;
  color: var(--blue-black);
  font-weight: 300;
  text-align: left;
}

.resultheadercol {
  font-weight: 500;
  border-bottom: 1px solid var(--light-blue);
  padding-bottom: 1rem;
}

.resultrow {
  font-family: var(--font-family);
  font-size: 1rem;
  color: var(--blue-black);
  font-weight: 300;
  text-align: left;
}

.resultrow label {
  background: #d7b1d7;
}

.form-check-input::before {
  background-color: var(--blue-black);
}

.stampduty {
  display: inline-flex;
}

.localfirmswitch input {
  width: 3em;
  padding-left: 1em;
  height: 3em;
}

.quoteBackground {
  background-color: var(--pale-grey);
  margin-top: 1em;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}

.marketplaceConveyancing {
  vertical-align: top;
  display: inline-flex;
  margin-left: 1rem;
  font-size: 0.9rem;
  float: right;
}

.marketplacecolumndata {
  font-size: 1.5rem;
  font-weight: 900;
}

.marketplacepricebreakdown {
  font-size: 0.9rem;
  vertical-align: top;
  text-decoration: underline;
  float: left;
}

.searchescol {
  text-align: left;
  padding-left: 0;
}

.controlrow {
  padding-top: 80px;
  padding-bottom: 80px;
}

.conveyancingfee {
  font-weight: 900;
}

.quoteResultsCard {
  background-color: var(--white);
  padding-top: 2em;
  padding-bottom: 2em;
  border-radius: 6px;
}

.referralFeeHeader {
  font-size: 1em;
  font-family: var(--font-family);
}

.referralFeeCard {
  font-size: 1em;
  font-family: var(--font-family);
  background-color: var(--white);
  margin-bottom: 2em;
  border-radius: 6px;
}

.change {
  font-family: var(--font-family);
  font-weight: 300;
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  color: var(--blue-black);
  border-radius: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.4rem;
  padding: 0;
  height: 2rem;
  width: 6rem;
}

.change:hover {
  background-color: var(--white);
  border: 1px solid var(--blue-black);
  color: var(--blue-black);
}

.save {
  font-family: var(--font-family);
  font-weight: 300;
  background-color: var(--blue-black);
  border: 1px solid var(--blue-black);
  color: var(--white);
  border-radius: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.4rem;
  padding: 0;
  height: 2rem;
  width: 9rem;
}

.save:hover {
  background-color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
  color: var(--white);
}

.generalInsurance {
  background-color: var(--pale-grey);
  padding: 2em;
  margin-bottom: -3em;
  border-radius: 6px;
}

.uinsureimage {
  display: flex;
  flex-direction: row;
  width: 58px;
  height: 80px;
  align-items: flex-start;
}

.uinsureimagesmall {
  display: flex;
  flex-direction: row;
  width: 38px;
  height: 60px;
  align-items: flex-start;
}

.uinsureheader {
  font-family: var(--font-family);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.modalHeader {
  font-family: var(--font-family);
  font-size: 1.5em;
  font-weight: 300;
}

.modalFooter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
}

.modalText {
  font-family: var(--font-family);
  color: var(--blue-black);
  font-size: 1em;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}

.spacer {
  padding-bottom: 1em;
}

.buttonFooter {
  margin: 4.5rem 2rem 1rem 0;
}

.giSubheader {
  font-family: var(--font-family);
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: left;
}

.gicheckbox {
  display: flex;
  flex-direction: row-reverse;
}

.gicheckbox label {
  padding-right: 2em;
}

.optincontainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.noresultsmessage {
  font-family: var(--font-family);
  font-size: 1em;
  padding-top: 2em;
}

.accordion {
  text-align: left;
  width: 100%;
}
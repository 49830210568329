@import "../shared/config.module.css";

.validationError {
  composes: error from "../../components/shared/forms.module.css";
}

.flexcontainer {
  composes: error from "../../components/shared/forms.module.css";
  width: unset;
}

.flexitem {
  composes: flexitem from "../../components/shared/layout.module.css";
}

.input {
  font-family: var(--font-family);
  font-size: 1em !important;
  background-color: white !important;
  color: var(--blue-black);
  font-weight: 300;
}

.checkbox {
    margin-top: 5px;
    margin-left: 10px;
    background-color: none;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 1em;
    color: var(--blue-black);
    display: flex;
    align-items:flex-start;
  }
  
  .checkbox input {
    position: static !important;
    margin-left: -1.25rem;
    margin-right: 1rem;
    width: 1.2rem;
    height: 1.4rem;
  }
  
  .checkbox label {
    vertical-align: top;
    padding-top: 0.3em;
  }

  .manualcontainer {
    background-color: var(--pale-grey);
    padding: 2.5em;
    font-family: var(--font-family);
    font-size: 1em;
    text-align: left;
    border-radius: 6px;
    margin-top: 15px;
  }

  .childManualInput {
      margin-top: 15px;
  } 

  .label {
    font-family: var(--font-family);
    text-align: left;
    align-items: flex-start;
    display: flex;
    padding: 0px;
  }

  .countryDropdown ul {
    max-height: 20rem;
    overflow-y: auto;
  }
.button {
    position: relative;
    text-transform: uppercase;
}

.button:disabled:hover {
    background-color: var(--light-blue);
}

.text {
    display: inline-block;
    vertical-align: middle;
}

.icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
}

.iconLeft {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.iconLeft svg {
    width: 100%;
    display: block;
}

.spinner {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 10px;
    top: 10px;
}
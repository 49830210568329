.container {
  margin: 0 1.25rem;
  text-align: left;
}

.container > * {
  margin: 2rem 0;
}

.manualAddressEntryFormContainer {
  max-width: 40rem;
}

@import "../../components/shared/config.module.css";

.card {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  background-color: var(--pale-grey);
  width: 100%;
  border-radius: 6px;
  border: 0;
  padding: 2.5em 2.5em 0em 2.5em;
  font-family: var(--font-family);
  font-size: 1em;
  min-height: 430px;
}

.cardheader {
  display: flex;
  flex-direction: column;
  border: 0;
  padding: 0px;
  background-color: var(--pale-grey);
}

.input {
  font-family: var(--font-family);
  font-size: 1.1em !important;
  background-color: var(--white) !important;
  color: var(--white);
  font-weight: 300;
  margin-top: 1em;
  border: 0px;
}

.title {
  font-family: var(--font-family);
  font-size: 1.8em;
  color: var(--blue-black);
  font-weight: 100;
  line-height: 1.2em;
}

.subtitle {
  font-family: var(--font-family);
  font-size: 1.2em;
  color: var(--blue-black);
  font-weight: 100;
  line-height: 1.2em;
}

.user {
  font-weight: 900;
}

.inlineflex {
  display: inline-flex;
}

.leftpad6x {
  padding-left: 6em;
}

.leftpad2x {
  padding-left: 1em;
}

.rightpad6x {
  margin-right: 6em;
}

.bottompad2x {
  padding-bottom: 2em !important;
}

.leftalign {
  text-align: left;
}

.rightalign {
  text-align: right;
}

.flexcontainer {
  display: inline-block;
  outline-style: none;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flexitem {
  color: var(--blue-black);
  font-family: var(--font-family);
  font-size: 1.2em;
  letter-spacing: -0.01em;
  font-weight: 300;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

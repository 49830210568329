@import "../../components/shared/config.module.css";

.iconToggle {
  float: right;
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  padding-right: 0.4em;
}

.card {
  border: 0;
  margin-bottom: 2.5em;
  border-radius: 6px;
}

.headerText {
  font-family: var(--font-family);
  font-size: 1em;
  line-height: 3.2rem;
  margin-bottom: 2.5em;
  padding-left: 1em;
}

.caseCount {
  font-family: var(--font-family);
  float: right;
  font-family: Montserrat;
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.newUpdatesCases {
  color: var(--pink);
}

.activeCases {
  color: var(--light-blue);
}

.closedCases {
  color: var(--purple);
}

.table {
  padding: 1em;
  background-color: white
}

.tableHeader {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 8px;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: var(--font-family);
  padding-bottom: 1rem;
  
}

.bandingHeader {
  grid-column: span 2 / span 2;
}

.internationalHeader {
  grid-column-start: 4;
}

.addButtonContainer {
  display: flex;
  align-items: flex-start;
  margin: 1em;
}
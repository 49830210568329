@import "../../components/shared/config.module.css";

.button {
  font-family: var(--font-family);
  font-weight: 300;
  background-color: var(--blue-black);
  border: 1px solid var(--blue-black);
  color: var(--white);
  border-radius: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.4rem;
  padding: 0 45px;
  height: 3rem;
  margin: 0.75rem;
  min-width: 12.5rem;
}

.button:hover {
  background-color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
}

.buttonMd {
  composes: button;
  font-size: 0.875rem;
  line-height: 1.2;
  height: 2rem;
  min-width: 9rem;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  align-items: center;
}

@media (min-width: 768px) {
  .buttonContainer {
    flex-direction: row;
    justify-content: flex-start;
  }

  .button {
    margin: 0 1.25rem 0 0;
  }
}

.backButton {
  font-family: var(--font-family);
  font-weight: 300;
  background-color: var(--blue-black);
  border: 1px solid var(--blue-black);
  color: var(--white);
  border-radius: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.4rem;
  height: 3rem;
  margin: 0.75rem 0.75rem 0.75rem 0;
  min-width: 12.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backButton:hover {
  background-color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
  color: var(--white);
  text-decoration: none;
}

.pageHeading {
  background: transparent;
  color: var(--blue-black);
  font-size: 1.5rem;
  text-align: left;
  line-height: 1.5;
  border: none;
  margin-top: 2rem;
  padding: 0 2.5rem;
  font-weight: 300;
}

.error {
  composes: error from "../../components/shared/forms.module.css";
}

.sectionHeading {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  color: var(--blue-black);
  font-size: 1.4em;
  font-weight: 300;
  font-family: var(--font-family);
  margin-bottom: 1em;
}

.legend {
  composes: p from "../../components/shared/typography.module.css";
  text-align: left;
}
@import "../shared/config.module.css";

.message {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 1rem;
  text-align: left;
  color: var(--blue-black);
}

.emphasis {
  font-weight: 700;
  display: inline;
}

ul {
  padding-left: 2em;
}

.dialog {
  display: flex;
  justify-content: center;
}

.dialog-content {
  min-width: 42.5rem;
  padding: 1.5rem;
}

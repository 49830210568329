
.listItem {
  padding: 0;
}

.container {
  padding-top: 1em;
  padding-bottom: 1em;
}

.listContainer {
  width: 100%;
  border: 1px solid gray;
  border-radius: 8px;
  margin-bottom: 1em;
}

.modalBody {
  text-align: left;
}

.modalFooter {
  align-items: left;
}
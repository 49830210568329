@import "../../components/shared/config.module.css";

.root {
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.7);
  box-shadow: inset 0 0 0 1px var(--grey-black);
  overflow: hidden;
}

.content {
  top: 25%;
  margin: 0 auto;
  z-index: 1001;
  max-width: 80.3rem;
  width: auto;
}

.modalHeader {
  font-family: var(--font-family);
  color: var(--blue-black);
  font-size: 1.5em;
  font-weight: 300;
}

.modalHeaderContainer {
  border-bottom: none;
}

.modalFooter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
  border-top: none;
}

.modalText {
  font-family: var(--font-family);
  color: var(--blue-black);
  font-size: 1em;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}

.spacer {
  padding-bottom: 1em;
}

.secondarybutton {
  font-family: var(--font-family);
  font-weight: 300;
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  color: var(--blue-black);
  border-radius: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.4rem;
  padding: 0 45px;
  height: 2.75rem;
  min-width: 11.5rem;
}

.secondarybutton:hover {
  background-color: var(--white);
  border: 1px solid var(--blue-black);
  color: var(--blue-black);
}

.primarybutton {
  font-family: var(--font-family);
  font-weight: 300;
  background-color: var(--blue-black);
  border: 1px solid var(--blue-black);
  color: var(--white);
  border-radius: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.4rem;
  padding: 0;
  height: 2.75rem;
  width: 11.5rem;
}

.primarybutton:hover {
  background-color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
  color: var(--white);
}

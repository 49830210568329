.viewQuoteContainer {
    font-family: var(--font-family);
    text-align: left;
}

.feesContainer {
    border-bottom: 2px solid var(--light-blue);
}

.vatText {
    margin: 2rem 2.5rem;
    font-size: 0.875rem
}

.quoteType {
    margin: 2rem 1.5rem;
    font-size: 1.5rem
}

.noteFooter {
    font-size: 0.875rem;
    margin: 2.5rem;
}

@import "../../../components/shared/config.module.css";

.dropdown > .customToggle > div {
  display: flex;
  font-family: var(--font-family);
  background-color: white;
}

.customToggle:hover {
  font-family: var(--font-family);
  text-decoration: none;
}

.toggleButtonLabel {
  font-family: var(--font-family);
  border: 1px solid #ced4da;
  height: 2.5rem;
  border-radius: 0 5px 5px 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
}

.toggleButton {
  font-family: var(--font-family);
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  color: var(--dark-blue);
  border: 1px solid #ced4da;
  border-radius: 5px 0 0 5px;
  width: 2.5rem;
  justify-content: center;
}

.filterInput {
  margin: 1rem;
  width: 90%;
}

.label {
  width: 100%;
  text-align: left;
}

.container {
  display: flex;
  flex-direction: column;
}

.error {
  align-self: flex-start;
  margin-top: 1rem;
}

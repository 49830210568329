.h1 {
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-transform: uppercase;
    background-color: transparent;
    text-align: left;
    color: var(--blue-black);
    font-size: 1.4em;
    font-weight: 300;
    font-family: var(--font-family);
    margin-bottom: 1em;
    border: 0;
}

.p {
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-align: left;
    color: var(--blue-black);
    font-size: 1em;
    font-weight: 300;
    font-family: var(--font-family);
    margin-bottom: 1em; 
}
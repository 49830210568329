.toast {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1050;
    border-radius: 0;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    width: 100vw;
}

.header {
    border: 0;
    padding: 20px;
    color: var(--blue-black);
    font-size: 16px;
}

.notificationRow {
  justify-content: space-evenly;
}

.emulateStopButton {
    font-family: var(--font-family);
    font-weight: 300;
    background-color: var(--blue-black);
    border: 1px solid var(--blue-black);
    color: var(--white);
    border-radius: 1.5rem;
    font-size: 0.9rem;
    line-height: 1.4rem;
    padding: 0;
    height: 2.4rem;
    width: 5rem;
  }
  
  .emulateStopButton:hover {
    background-color: var(--dark-blue);
    border: 1px solid var(--dark-blue);
    color: var(--white);
  }
.expander {
  width: 50px;
  flex-shrink: 0;
}

.header {
  flex-grow: 1;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.headerContainer {
  display: flex;
  height: 100%;
  padding: 10px;
  gap: 5px;
  border-bottom: 0.1rem solid #dee2e6;
  font-weight: bold;
}

.headerContainer > div {
  border-radius: 5px;
  padding: 8px;
}

.header {
  flex-grow: 1;
}

.viewQuoteContainer {
    font-family: var(--font-family);
    text-align: left;
}

.quoteDetailsContainer {
    width: 100%;
    border: 2px solid var(--light-blue);
    border-radius: 5px;
    padding: 1rem;
}

.quoteDetailsTable {
    margin: 0;
}

.feesContainer {
    border-bottom: 2px solid var(--light-blue);
}

.vatText {
    margin: 2rem 2.5rem;
    font-size: 0.875rem
}

.grandTotalHeading {
    margin-top: 1rem;
}

.noteFooter {
    font-size: 0.875rem;
    margin: 2.5rem;
}

.buttonRow {
    margin-top: 4rem;
}

.printButton {
    position: absolute;
    right: 25px;
    top: 40px;
}

.uinsureFooter {
    padding-top: 2rem;
}
@import "../shared/config.module.css";

.hide {
  display: none;
}

.show {
  display: inline;
}

.flexcontainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  outline-style: none;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: center;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  /* padding-left: 6em; */
  /* margin-right: 6em; */
  margin-top: 2.5em;
}

.flexitem {
  height: 10em;
  width: 7em;
  color: var(--blue-black);
  font-family: var(--font-family);
  font-weight: 300;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.spacer::after {
  content: "";
  font-size: 0.9em;
  margin: 0 4em;
}

.radioImage {
  width: 5em;
  height: 5em;
  cursor: pointer;
}

.hiddenradio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

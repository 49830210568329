@import "../../components/shared/config.module.css";

.flexcontainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flexitem {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 0.8em;
  margin-bottom: 1.6em;
}

.flex {
  display: flex;
}

.networklogo {
  max-width: 190px;
  max-height: 72px;
  margin-right: 20px;
}

.customGutter {
  padding: 0px 0px 0px 0px;
}

.navigation {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.flexitem:nth-child(1) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

/* .flexitem:nth-child(2) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  margin-left: 6em;
}

.flexitem:nth-child(3) {
  margin-right: 6em;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  margin-left: auto;
} */

.headerlink {
  background-color: var(--white);
  border: 1px solid var(--light-blue);
  color: var(--blue-black);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  padding: 0.5rem 0.75rem;
}

.headerlink:hover,
.headerlink:focus {
  background-color: var(--white);
  border: 1px solid var(--blue-black);
  color: var(--blue-black);
  border-radius: 20px;
  font-weight: 300;
  font-family: var(--font-family);
  font-size: 0.9em;
  padding: 0.5rem 0.75rem;
}

.spacer::after {
  content: "";
  font-size: 0.9em;
  margin: 0 0.5em;
}
